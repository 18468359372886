<template>
  <div>
    <div class="materialDetailBox">
      <div class="materialDetailHeader">
        <BreadCrumbs :breadList="breadList"></BreadCrumbs>
        <MiniSearch searchType="素材"></MiniSearch>
      </div>
      <div class="materialDetailContent">
        <div class="contentLeft">
          <DetailImgShow
            :srcList="detailInfo.preview"
            type="material"
          ></DetailImgShow>
        </div>
        <div class="contentRight">
          <div class="rightTop">
            <DetailInfo
              :authorInfo="authorInfo"
              :fid="7"
              @downloadFun="downloadFun"
              @getYunCodeFun="getYunCodeFun"
              :info="detailInfo"
              :detailId="detailId"
              type="1"
            ></DetailInfo>
						<div class="free" @click="freeReceive">
							<img src="../../assets/image/free.gif" alt="">
						</div>
          </div>
          <div class="rightBottom">
            <AuthorMore
              :authorInfo="authorInfo"
              :authorOther="authorOther.slice(0, 6)"
              type="material"
            ></AuthorMore>
          </div>
        </div>
      </div>
    </div>

    <div class="recommendBox">
      <p class="f22 c_33 f_b">相关资料推荐</p>
      <MaterialList 
				ref="list"
				class="mt30"
				:materialList="recommendList" 
				@nextPage="nextPage"
				:infinite="true"
				:listStatus="listStatus"
				>
			</MaterialList>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import MiniSearch from "@/components/MiniSearch";
import DetailImgShow from "@/components/DetailImgShow";
import DetailInfo from "@/components/DetailInfo";
import AuthorMore from "@/components/AuthorMore";
import MaterialList from "@/components/MaterialList";
import {
  cloudLibrarygetCateNav,
  getRelatedMaterial,
  getAuthorInfo,
  getMaterialDetail,
  getMaterialCanDown,
	checkDownTimes
} from "../../Api";
import { getToken } from "@/utils/auth";
import { mapGetters } from "vuex";

export default {
  name: "MaterialDetail",
  components: {
    BreadCrumbs,
    MiniSearch,
    DetailImgShow,
    DetailInfo,
    AuthorMore,
    MaterialList,
  },
  data() {
    return {
      downloadUrl: "",
      detailId: "",
      breadList: [],
      detailInfo: {
        preview: [],
      },
      authorInfo: {},
      recommend: [],
      authorOther: [],
      payObj: {
        payStatus: "noPay",
        isTopUp: false,
      },
			loading: true,
			getListParams: {
				id: this.$route.query.detailId,
				page: 0,
				per_page: 30,
			},
			listStatus: {
				isData: true,
				isNull: false,
			},
			shareId: '',
    };
  },
  created() {
    this.detailId = this.$route.query.detailId;
		if (this.$route.query.shareId) {
			this.shareId = this.$route.query.shareId;
		}
    this.initData();
  },
  methods: {
    initData() {
      Promise.all([
        this.getCateNav(),
        this.getDetail(),
        this.getAuthor(),
      ]).then(() => {
        console.log("初始化完成");
      });
    },
		// 免费领知币弹窗
		freeReceive() {
			this.$picturePop().show()
		},
    //面包屑
    getCateNav() {
      new Promise((resolve) => {
        cloudLibrarygetCateNav(this.detailId).then((res) => {
          this.breadList = res.data;
          resolve();
        });
      });
    },
    //  素材详情
    getDetail() {
      new Promise((resolve) => {
        const params = {
          id: this.detailId,
          pid: this.detailId,
          token: getToken(),
        };
				if (this.shareId) {
					params.share_id = this.shareId
				}
        getMaterialDetail(params).then((res) => {
          this.detailInfo = res.data;
          document.title = res.data.name + " - 资料库 - 知设网";
          resolve();
        });
      });
    },
    //  作者信息
    getAuthor() {
      new Promise((resolve) => {
        getAuthorInfo({ id: this.detailId }).then((res) => {
          this.authorInfo = res.data;
          getRelatedMaterial({ uid: res.data.uid, fid: 7 }).then((ress) => {
            this.authorOther = ress.data;
            resolve();
          });
        });
      });
    },
		// 加载下一页
		nextPage() {
			this.getListParams.page += 1;
		},
    // 获取提取码
    getYunCodeFun() {
      //判断是否登录
      this.$login({ isRouter: false }).then((res) => {
        const { isLogin } = res;
        if (isLogin) {
          this.getCanDown().then((canDowRes) => {
            if (canDowRes === "appreciate") {
              const info = { ...this.detailInfo };
              if (info.user_is_vip == 1) {
                info.zsStr = (info.zsStr * 0.8).toFixed(1);
              }
              const data = {
                //传递给函数式组件的值（慎用哦）
                userToken: getToken(),
                detailId: this.detailId, // 项目id
                balance: this.balance, // 知币余额
                projectInfo: info, // 项目详情
                authorUid: this.authorInfo.uid, // 作者uid
                title: "下载素材",
                titleDec: "素材名称",
              };
              this.payObj = this.$payPop({ data: data }); // 支付弹窗
              this.payObj.show();
            }
          });
        }
      });
    },
    //下载素材
    downloadFun(shareId) {
      this.$login({ isRouter: false }).then((res) => {
        const { isLogin } = res;
        if (isLogin) {
          this.getCanDown(shareId).then((canDowRes) => {
            if (canDowRes === "appreciate") {
              const info = { ...this.detailInfo };
              if (info.user_is_vip == 1) {
                info.zsStr = (info.zsStr * 0.8).toFixed(1);
              }
              const data = {
                //传递给函数式组件的值（慎用哦）
                userToken: getToken(),
                detailId: this.detailId, // 项目id
                balance: this.balance, // 知币余额
                projectInfo: info, // 项目详情
                authorUid: this.authorInfo.uid, // 作者uid
                title: "下载素材",
                titleDec: "素材名称",
              };
              this.payObj = this.$payPop({ data: data }); // 支付弹窗
              this.payObj.show();
            } else if (canDowRes === "down") {
							checkDownTimes({token: getToken(), id: this.detailId}).then((res) => {
								if (res.data&&res.data.error == 0) {
									let downloadUrl =
										this.$ROOT_DIR +
										"/web/CloudLibraryDetail/down?id=" +
										this.detailId +
										"&token="+getToken()
									if (shareId) {
										downloadUrl = downloadUrl +"&share_id="+encodeURIComponent(shareId);
									}
									let link = document.createElement("a");
									link.style.display = "none";
									link.href = downloadUrl;
									document.body.appendChild(link);
									link.click();
								} else if (res.data&&res.data.error == -1) {
									this.$tooltipPop().show(1, res.data.msg)
								} else if (res.data&&res.data.error == -2) {
									this.$tooltipPop().show(2, res.data.msg)
								} else {
									this.$tooltipPop().show(0, res.data.msg)
								}
							}).catch((err) => {
								console.log(err);
							});
            }
          });
        }
      });
    },
    //判断是否需要支付购买
    getCanDown(shareId) {
      return new Promise((resolve) => {
        const params = { token: getToken(), pid: this.detailId, share_id: '' };
				if (shareId) {
					params.share_id = decodeURIComponent(shareId)
				}
        getMaterialCanDown(params).then((res) => {
          const canDownStatus = res.data.action;
          resolve(canDownStatus);
        });
      });
    },
  },
  computed: {
    ...mapGetters([
      "isLogin",
      "balance", // 知币余额
			"recommendList"
    ]),
  },
  watch: {
    "payObj.payStatus"(val) {
      // 监听支付弹窗中是否支付成功
      if (val === "paySuccess") {
        this.initData();
      }
    },
    "payObj.isTopUp"(val) {
      // 监听支付弹窗中是否支付成功
      if (val) {
        this.$topUpPop({ data: { balance: this.balance } });
      }
    },
		getListParams: {
			handler: function () {
				this.loading = true;
				(this.listStatus.isData = true), (this.listStatus.isNull = false);
				this.$store
					.dispatch("app/getRelatedMaterial", this.getListParams)
					.then((res) => {
						this.loading = false;
						const { data } = res;
						if (data.total == 0) {
							this.$refs.list.clear();
							this.listStatus.isNull = true;
						} else if (data.current_page == data.last_page) {
							this.listStatus.isData = false;
						}
					});
			},
			deep: true,
		},
  },
};
</script>

<style scoped>
.recommendBox {
  margin-top: 0.2rem;
  width: 100%;
  min-width: 1400px;
  padding: 0 1.2rem;
}

.rightBottom {
  margin-top: 0.1rem;
  width: 100%;
  background: #ffffff;
  border-radius: 0.04rem;
  flex: 1;
  overflow: hidden;
}

.rightTop {
  width: 100%;
  height: 4.64rem;
  background: #ffffff;
  border-radius: 0.04rem;
	position: relative;
}

.free {
	position: absolute;
	top: 0;
	right: -0.75rem;
	cursor: pointer;
}
.free img {
	width: 0.55rem;
	/* height: 1.875rem; */
}

.contentLeft {
  flex: 1;
  margin-right: 0.2rem;
  overflow: hidden;
  background: #ffffff;
  padding: 0.3rem 0.5rem;
}

.contentRight {
  width: 4rem;
  display: flex;
  flex-direction: column;
}

.materialDetailContent {
  display: flex;
  height: 8.3rem;
}

.materialDetailBox {
  width: 100%;
  min-width: 1400px;
  background: #f7f6fb;
  padding: 0 2.8rem 0.4rem;
}

.materialDetailHeader {
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>