<!--
 * @FilePath: \zsw_new_edition\src\components\MaterialList.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-08 14:52:38
-->
<!--素材列表组件-->
<template>
	<div ref="box">
		<el-skeleton style="width: 100%" :loading="loading && !list.length" animated :count="6">
			<div slot="template">
				<el-skeleton-item
					variant="image"
					style="width: 2.6rem; height: 3rem; margin-bottom: 0.4rem"
				/>
			</div>
			<div v-if="list.length > 0 || loading">
				<div class="suList">
					<router-link
						:to="{ path: '/material/detail', query: { detailId: item.id } }"
						target="_blank"
						class="suItem"
						v-for="(item, index) in list"
						:key="index"
					>
						<div class="suItemImg">
							<img :src="item.img" alt="" />
						</div>
						<div class="suItemTitle txt-over">{{ item.title }}</div>
					</router-link>
					<div style="height: 0; width: 2.6rem"></div>
					<div style="height: 0; width: 2.6rem"></div>
					<div style="height: 0; width: 2.6rem"></div>
					<div style="height: 0; width: 2.6rem"></div>
				</div>
				<div style="height: 40px;">
					<img class="loadImg" :class="{ loadImgs: !loading }" src="@/assets/image/loading.gif" alt="">
					<p class="loadText" :class="{ loadTexts: !loading && !listStatus.isData }">
						没有更多了...
					</p>
				</div>
			</div>
			<div v-else>
				<el-empty description="没有内容哦"></el-empty>
			</div>
		</el-skeleton>
	</div>
</template>

<script>
export default {
  name: "MaterialList",
  props: {
    materialList: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
		infinite: {
			type: Boolean,
			default: false, // 判断是否需要无限滚动
		},
		listStatus: {
			type: Object,
			default: () => {
				return {
					isData: true,	// 判断是否有下一页
					isNull: false	// 判断是否为空
				}
			}
		}
	},
	data() {
		return {
			list: [],
			isLoading: false,
		};
	},
	methods: {
		// 清除数据
		clear() {
			this.list = []
		},
		// 翻页
		loadmore() {
			if (this.listStatus.isNull || !this.listStatus.isData) return;
			this.$emit("nextPage");
		},
		// 添加数据
    pushData() {
			if (this.materialList && this.materialList.data && this.materialList.current_page > 1) {
				this.list = this.list.concat(this.materialList.data);
			} else if (this.materialList && this.materialList.data && this.materialList.data.length){
				this.list = this.materialList.data
				this.$waterfall.forceUpdate() 
			}
    },
		fnThrottle (method, delay, duration) {
			var that = this;
			var timer = this.timer;
			var begin = new Date().getTime();
			return function(){
				var context = that;
				var args = arguments;
				var current = new Date().getTime();
				clearTimeout(timer);
				if(current-begin>=duration){
					method.apply(context,args);
					begin=current;
				}else{
					that.timer=setTimeout(function(){
						method.apply(context,args);
					}, delay);
				}
			}
		},
		// 判断滚动位置
		handleScroll() {
			const scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			const windowHeight =
				document.documentElement.clientHeight || document.body.clientHeight;
			const offsetTop = this.$refs.box.offsetHeight;
			if (scrollTop + windowHeight >= offsetTop && !this.loading) {
				// console.log(this.listStatus.isNull, !this.listStatus.isData,);
				//到了这个就可以进行业务逻辑加载后台数据了
				if (this.listStatus.isNull || !this.listStatus.isData) return;
				this.fnThrottle(this.loadmore, 200, 150)()
			}
		},
	},
	mounted() {
		this.pushData();
		window.addEventListener("scroll", this.handleScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	watch: {
		materialList: {
			handler: function () {
				this.pushData();
			},
			deep: true,
		}
	}
};
</script>

<style scoped>
.el-empty{
	width:100%
}
.loadImgs {
	height: 0;
}
.loadText {
	height: 0;
	line-height: 40px;
	overflow: hidden;
	text-align: center;
	transition: 0.5s;
	color: black;
}
.loadTexts.loadText {
	height: auto;
	display: block;
}
.loadImg {
	display: block;
	margin: 0 auto;
	color: black;
  transition: 0.5s;
	text-align: center;
}
.suItemTitle {
  color: #22242E;
  font-size: 0.16rem;
  margin-top: 0.2rem;
  width: 2.5rem;
  font-weight: 600;
}

.suItem {
  width: 2.6rem;
   /* flex: 0 0 16.66%; */
  /* margin-right: 0.24px; */
  margin-bottom: 0.4rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}

.suItem:nth-child(6n+0) {
  padding-right: 0 !important;
}
.suItem:hover {
  transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
}

.suItemImg {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 0.08rem;
  overflow: hidden;
}
.suItemImg img {
  width: 2.6rem;
  height: 2.6rem;
  /* max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

::v-deep .suList {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>